<!--
  [pending]
  - feature button will overflow when both l.research & l.compose is opened
  - some tabs is missing in the new UI
    list of LEGACY tabs/paths:
      - /tutorial
      - /pricing
      - /settings/profile
      - /settings/clients
      - /settings/billing_details
-->
<template>
  <aside :class="['sidebar', { 'collapsed': sidebarCollapsed }]" id="SidebarLeft">
    <div class="toggle-container">
      <button class="collapse-btns" 
        @click="toggleSidebar" 
        :style="sidebarMargin">
        <span class="material-symbols-rounded">{{ sidebarCollapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}</span>
      </button>
    </div>
    <router-link to="/overview" class="logo mb-4">
      <img v-if="!sidebarCollapsed" class="full-logo" src="../assets/logo/nexlaw_blue_resize.png" alt="NexLaw" />
      <img v-else class="collapsed-logo" src="../assets/logo/nl.png" alt="NexLaw" />
    </router-link>

    <!-- Hide temporarily until new tutorial videos -->
    <!-- <router-link
      to="/gettingstart"
      :class="['getting-started', pathCheck('gettingstart', 'gettingstart') && 'active']"
       v-b-tooltip.top="sidebarCollapsed ? { title: 'Getting Started' } : false"
    >
      <span class="material-symbols-rounded me-2" style="margin:auto -1px;">flag</span>
      <span v-if="!sidebarCollapsed">Getting Started</span>
    </router-link> -->

    <router-link
      to="/overview"
      :class="['getting-started', pathCheck('overview', 'overview') && 'active']"
      v-b-tooltip.top="sidebarCollapsed ? { title: 'Overview' } : false"
    >
      <span class="material-symbols-rounded me-2" style="margin:auto -1px;">grid_view</span>
      <span v-if="!sidebarCollapsed">Overview</span>
    </router-link>

    <div
      v-for="(items, sec) in section"
      :key="`section-${sec}`"
      :class="[ 'section', sec === 'Others' && 'border-tp mb-5', (sec === 'Main Menu' || sec === 'Your AI Legal Assistant') && 'border-tp']"
    >
      <p style="white-space: nowrap;" class="section-title" v-if="!sidebarCollapsed">{{ sec }}</p>
      <div>
        <span v-for="(feature, key) in items" :key="`feature-${key}`">
          <template v-if="permissionCheck(feature.p_key)">
            <router-link
              v-if="feature.link && feature.collapse === undefined"
              :to="feature.link || '/'"
              :class="pathCheck(key, feature.check) && 'active'"
              v-b-tooltip.top="(sidebarCollapsed || key === 'NeXa') ? { title: key === 'NeXa' ? 'NeXa Expert Mode' : key } : false"
            >
              <span @click="autoToggleSidebar(key)" class="material-symbols-rounded" style="margin:auto 8px;">{{ feature.icon }}</span>
              <span style="white-space: nowrap;" @click="autoToggleSidebar(key)" v-if="!sidebarCollapsed">{{ key }}</span>
              <sup v-if="feature.new && !sidebarCollapsed" class="bg">New</sup>
            </router-link>

            <template v-else>
              <a
                tabindex="0"
                :id="`toggle-${formatKey(key)}`"
                v-b-toggle="`collapse-${formatKey(key)}`"
                v-b-tooltip="{ title: key, placement: 'right' }"
                :aria-controls="`collapse-${formatKey(key)}`"
              >
                <span class="material-symbols-rounded" style="margin:auto 8px;">{{ feature.icon }}</span>
                {{ key }}
                <span class="material-symbols-rounded arrow">expand_more</span>
              </a>
              <b-collapse :id="`collapse-${formatKey(key)}`" v-model="feature.collapse">
                <router-link
                  v-for="(child, c_key) in feature.child"
                  :key="`child-feature-${c_key}`"
                  :to="child.link"
                  :class="pathCheck(c_key, child.check) && 'active'"
                  v-b-tooltip.top="{ title: c_key}"
                >
                  <span class="material-symbols-rounded">{{ child.icon }}</span>
                  {{ c_key }}
                </router-link>
              </b-collapse>
            </template>
          </template>
        </span>
      </div>
    </div>

    <a v-if="!sidebarCollapsed" id="Stay-Updated" href="https://www.linkedin.com/company/nexlaw-ai" target="_blank">
      <p>Stay Connected: Get the Latest News and Updates</p>
      <img src="@/assets/icon/icon-linkedin-blue.svg" alt="Follow Our LinkedIn for Latest Updates" />
      <span class="material-symbols-rounded">arrow_forward</span>
    </a>
    <button v-else @click="openLinkedIn" style="margin-top: auto; margin-bottom: 15px;">
      <img class="linkin" src="@/assets/icon/icon-linkedin-blue.svg" alt="Follow Our LinkedIn for Latest Updates" />
    </button>
  </aside>
</template>

<script>
import general from '../store/general';

/* eslint-disable */
export default {
  data() { return {
    sidebarCollapsed: sessionStorage.getItem('sidebarCollapsed') === 'true',
    section: {
      /** 
       * <Section>: {   // Title of the section
       *   <Feature>: { // Name of the feature
       *     icon:  <Icon>  // A valid icon name of Google Material Symbols
       *     p_key: <P Key> // Permission key required to use the feature (acquire from localStorage)
       *     link:  <Path>  // Path to the main page of the feature
       *     check: <Path>  // Root path used to check for current feature (optional)
       *                    ** use if the path is not similar to <Feature>
       * 
       *     collapse: <Boolean>  // toggle collapse status for collapsible section (optional)
       *     child: {             // sub-features of the <Feature> (binds with collapse property, optional)
       *       icon:  ...
       *       link:  ...
       *       check: ...
       *     } 
       *   }
       * }
       */
      "Your AI Legal Assistant": {
        "NeXa": {
          icon: "smart_toy",
          link: "/expert_mode",
          check: "/expert_mode",
          new: true,
        },
      },
      "Main Menu": {
        "Legal AI Trial Copilot": {
          icon: "gavel",
          p_key: "nexlaw.les",
          link: "/legal_copilot",
          check: "/legal_copilot",
        },
        "Legal Advice": {
          icon: "contract",
          p_key: "nexlaw.les",
          link: "/legal_advice",
          check: "/legal_advice",
        },
        // "Legal Research": {
        //   icon: "plagiarism",
        //   p_key: "nexlaw.legal-argument",
        //   collapse: false,
        //   child: {
        //     "Legal Argument": {
        //       icon: "article",
        //       link: "/legal_research/argument",
        //       check: "/legal_research/argument",
        //     },
        //     "Legal Memo / Opinion": {
        //       icon: "article",
        //       link: "/legal_research/memo_opinion",
        //       check: "/legal_research/memo_opinion",
        //     },
        //   },
        // },
        "Case Law Summarizer": {
          icon: "description",
          p_key: "nexlaw.case-summarizer",
          link: "/case_law_summarizer"
        },
        "Legal Research": {
          icon: "plagiarism",
          p_key: "nexlaw.legislation-search",
          link: "/legal_research",
          check: "/legal_research",
        },
        // "Legal Writer": {
        //   icon: "edit_document",
        //   p_key: "nexlaw.les",
        //   link: "/legal_writer",
        //   check: "/legal_writer",
        // },
        // "Contract Reviewer": {
        //   icon: "contract_edit",
        //   p_key: "nexlaw.contract-review",
        //   link: "/contract_reviewer",
        //   check: "/contract_reviewer",
        // },
        // "Case Law Search": {
        //   icon: "search",
        //   p_key: "nexlaw.case-search",
        //   link: "/case_law_search"
        // },
        // "Legislation Search": {
        //   icon: "quick_reference_all",
        //   p_key: "nexlaw.legislation-search",
        //   link: "/legislation_search"
        // },
        // "Legal Compose": {
        //   icon: "edit_document",
        //   p_key: "nexlaw.letter-of-demand|nexlaw.contract-review",
        //   collapse: false,
        //   child: {
        //     "Legal Writer": {
        //       icon: "contract_edit",
        //       link: "/legal_compose/legal_writer",
        //     },
        //     "Contract Review": {
        //       icon: "contract_edit",
        //       link: "/legal_compose/contract_review/category",
        //     },
        //   },
        // },
      },
      "Others": {
        "Activity Log": {
          icon: "history",
          link: "/activity_log",
        },
        // "Help": {
        //   icon: "help",
        //   link: "/overview", // temp
        // },
      },
    },

    AuthInterval: null,
  }},

  computed:{
    sidebarMargin() {
        return {
          marginLeft: this.sidebarCollapsed ? '3.75rem' : '17.5rem' // 60px and 280px converted to rem
        };
    },
  },

  methods: {
    formatKey(k) { return k.replaceAll(" ", "-"); },
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/nexlaw-ai', '_blank');
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
      sessionStorage.setItem('sidebarCollapsed', this.sidebarCollapsed);
      this.$emit("toggleSidebar",this.sidebarCollapsed);
    },
    autoToggleSidebar(key) {
        if (key === "NeXa") {
          if (!this.sidebarCollapsed) {
            this.sidebarCollapsed = true;
            sessionStorage.setItem('sidebarCollapsed', this.sidebarCollapsed);
            this.$emit("toggleSidebar", this.sidebarCollapsed);
      }
        }
    },
    pathCheck(k, c = null) {
      var path = c ?? `/${k.replaceAll(" ", "_").toLowerCase()}`;
      return new RegExp(path).test(this.$route.fullPath);
    },

    permissionCheck(key) {
      // does not required permission check
      if (key === undefined) return true;

      // for multiple permissions scenario
      if (key.split("|").length > 1) {
        var status = true;
        key.split("|").forEach(e => {
          if (!status) return false;
          status = localStorage.permissions.includes(e);
        });
        return status;
      }
      
      // single permission
      else return localStorage.permissions.includes(key);
    },
  },

  created() {
    this.AuthInterval = setInterval(() => {
      if (this.$route.name !== "Login") general.GetMe();
    }, 60000);
  },

  mounted() {
    const savedState = sessionStorage.getItem('sidebarCollapsed');
    if (savedState !== null) {
      this.sidebarCollapsed = savedState === 'true';
    }

    this.autoToggleSidebar();
    
    // toggle collapsible automatically when in the feature

    // if (this.$route.fullPath.includes("/legal_research")) {
    //   this.section['Main Menu']['Legal Research'].collapse = true;
    // }
    if (this.$route.fullPath.includes("/legal_compose")) {
      this.section['Main Menu']['Legal Compose'].collapse = true;
    }
  },

  beforeDestroy() { clearInterval(this.AuthInterval); },
};
</script>

<style scoped>
aside {
  width: 300px;
  height: 100vh;
  background-color: white;
  transition: 0.3s ease-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--light-grey);
  overflow-y: auto;
  z-index: 100;
  scrollbar-width: none;
  -ms-overflow-style: none; 
}
aside.sidebar::-webkit-scrollbar {
  display: none;
}
.getting-started.active {
  background-color: var(--primary);
  height: 40px;
  line-height: 40px;
}
.getting-started,
.overview {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.sidebar.collapsed {
  width: 75px; /* Collapsed width */
}
.hidden-links {
  display: none;
}
.collapse-btns {
  top: 1.4rem;
  background-color: #FFFFFF;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; 
  box-shadow: 0 0 5px #AAABAF;
  cursor: pointer;
}
.getting-started {
  /* margin: 10px 20px 0px 20px !important; */
}

.section {
  margin-top: 10px;
}

.section p {
  margin: 10px 30px 0px 20px;
  font-size: 13px;
  font-weight: 400;
  color: var(--dark-grey) !important;
}

.section div {
  margin-top: 10px;
}

.border-tp {
  border-top: 1.5px solid var(--light-grey);
}
</style>

<!-- NexLaw Logo -->
<style scoped>
a.logo {
  margin: 0px;
  padding-top: 5px;
  height: auto;
  width: 100%;

  &:hover {
    background-color: transparent;
  }
}

.logo .full-logo {
  width: 100px;
  height: 38px;
  margin: 16px auto 10px 10%;
}

.logo .collapsed-logo {
  width: 40px;
  height: 40px;
  margin: 14px auto 10px 26%;
}

</style>

<!-- Feature Buttons -->
<style scoped>
a {
  margin: 0px 20px;
  padding-right: 8px;
  height: 40px;
  width: calc(100% - 40px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  color: var(--neutral-black);

  transition: all .1s ease-in-out;
  
  &:hover {
    color: var(--primary);
    background-color: var(--light-blue);
  }
  

}


a.active {
  background-color: var(--primary) !important;
  color: white !important;
}

a .material-symbols-rounded {
  font-size: 19px;
}

.collapse {
  margin: 0px !important;
}

a .arrow {
  margin: auto 2px auto auto;
}

.collapse a .material-symbols-rounded {
  margin: auto 10px auto 20px;
}
</style>

<!-- Stay Connected Banner -->
<style scoped>
#Stay-Updated {
  width: 100%;
  bottom: 0px;
  margin: 0px;
  margin-top: auto;
  padding: 0px;
  border-radius: 0px;
  height: auto;
  color: var(--neutral-black);
  background-color: var(--light-grey);
}
#Updated{
  position: fixed;
  bottom: 0px;
}
#Updated img {
  width: 20px;
  height: 20px;
}
#Stay-Updated p {
  width: calc(75% - 40px);
  margin: 10px 10px 10px 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  
}

#Stay-Updated img {
  width: 20px;
  height: 20px;
}

#Stay-Updated span {
  font-size: 24px;
  margin-left: 15px;
}
.bg{
  display: flex;
  align-items: center;
  width: 26px;
  height: 14px;
  padding: 1px 2px;
  border-radius: 4px;
  background: var(--status-blue);
  color: #FFFFFF;
  margin-left: 5px;
}
.linkin{
  margin: auto;
  height: 24px;
  width: 24px;
}
</style>