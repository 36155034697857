<!--
  [pending]
  - Search section: pending Design
  - User profile picture: discussion needed with Design/DB
  - User section: pending Design
-->
<template>
  <div id="Header" @click="hideAllDropdown">
    <label
      id="Search"
      for="search"
    >
      <span class="material-symbols-rounded">search</span>
      <input id="search" placeholder="Search anything here..."/>
    </label>

    <p id="Subscription">
      {{ `${SubsDay} Day${SubsDay > 1 ? "s" : ""} left in your subscription` }}
    </p>

    <span id="Divider"></span>

    <div id="User">
      <span class="material-symbols-rounded account">account_circle</span>
      <h6>{{ userName }}</h6>
      <span class="material-symbols-rounded arrow">expand_more</span>

      <b-tooltip
        target="User"
        triggers="click"
        placement="bottom"
        id="HeaderUserDropdown"
        custom-class="DROPDOWN-MENU"
      >
        <div
          v-for="(option, id) in options"
          :key="`dropdown-option-${id}`"
          class="dropdown-option"
          @click="performAction(id)"
        >
          <span class="material-symbols-rounded">{{ option.icon }}</span>
          <p>{{ option.label }}</p>
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { calculateSubscription } from '../store/utils';

export default {
  data() { return {
    SubsDay: 0,

    // User section
    userName: "",
    options: [
      {
        icon: "account_circle",
        label: "View Profile",
      },
      {
        icon: "star",
        label: "My Bookmarks",
      },
      {
        icon: "archive",
        label: "Archive",
      },
      {
        icon: "logout",
        label: "Log Out",
      },
    ],
  }},

  methods: {
    hideAllDropdown() {
      this.$root.$emit('bv::hide::tooltip');
    },

    performAction(o) {
      this.hideAllDropdown();
      switch(o) {
        // view profile
        case 0:
          this.$router.push({ name: "profile" }); 
           break;

        // my bookmarks
        case 1:
          this.$router.push({ name: "Bookmark" });
          break;

        // archived cases?
        case 2:
          this.$router.push({ name: "Archieve" }); 
          break;

        case 3: // logout
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("permissions");
          localStorage.removeItem("me");
          this.$router.push({ name: "Login" });
          break;
      }
    },
  },

  created() {
    this.userName = JSON.parse(localStorage.getItem("userInfo")).name;
    this.SubsDay = calculateSubscription();
  }
}
</script>

<style scoped>
#Header {
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--light-grey);
  background-color: white;
  padding-left: 20px;
}

#Subscription {
  height: 36px;
  padding: 6px 10px;
  margin-bottom: 0px;
  background-color: var(--interface-grey);
  border-radius: 8px;
  color: var(--primary) !important;
  font-weight: 500;
}

#Divider {
  margin: auto 10px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
</style>

<!-- Search Section -->
<style scoped>
#Search {
  flex-grow: 1;
  height: 48px;
  display: flex;
  align-items: center;
}

#Search span {
  margin: 12px;
  color: var(--dark-grey);
}

#Search input {
  width: calc(100% - 60px);
}
</style>

<!-- User Section -->
<style scoped>
#User {
  height: 48px;
  width: 200px;
  margin: 8px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #F2F9FF;
  }

  &:hover h6 {
    color: var(--primary) !important;
  }
}

#User .account {
  font-size: 32px;
  margin: 0px 10px;
  color: var(--dark-grey);
  border-radius: 50%;
}

#User h6 {
  flex-grow: 1;
  margin-bottom: 0px;
}

#User .arrow {
  margin: 15px;
}
</style>

<style>
#HeaderUserDropdown {
  left: -10px !important;
  width: 210px;
}

#HeaderUserDropdown .tooltip-inner {
  max-width: 1000px !important;
}
</style>