<!--
  Paths that should not have Sidebar & Header displayed
  should be updated into the computed:showMenu() 's list manually
<template>
  <div class="d-flex">
    <SidebarLeft v-if="showMenu" />
    <div class="content-panel">
      <Header v-if="showMenu"></Header>
      <router-view
        :class="$route.fullPath!=='/login' && 'page-content'"
        :key="$route.path"
      />
    </div>
  </div>
</template>

<script>
import general from "../store/general";
import SidebarLeft from "@/Pages/SidebarLeft.vue";
import Header from "@/Pages/Header.vue";

export default {
  components: { SidebarLeft, Header, },

  computed: {
    showMenu() { return ![
      'Login'
    ].includes(this.$route.name); },
  },

  created() {
    this.showMenu && general.GetMe();
  },
}
</script>

<style scoped>
.content-panel { width: calc(100vw - 280px); }
</style>
-->

<template>
  <div class="main-container">
    <!-- Sidebar only visible when showMenu is true -->
    <SidebarLeft
      v-if="showMenu"
      :collapsed="sidebarCollapsed"
      @toggleSidebar="toggleSidebar"
    />

    <!-- Adjust content-panel based on whether Sidebar is shown or not -->
    <div :class="['content-panel']" :style="contentPanelStyle">
      <!-- Header only visible when showMenu is true -->
      <Header v-if="showMenu" />

      <!-- Main content area -->
      <router-view
        :class="$route.fullPath !== '/login' && 'page-content'"
        :key="$route.path"
      />
    </div>
  </div>
</template>

<script>
import general from "../store/general";
import SidebarLeft from "@/Pages/SidebarLeft.vue";
import Header from "@/Pages/Header.vue";

export default {
  data() {
    return {
      sidebarCollapsed: false,
    };
  },
  components: { SidebarLeft, Header },

  computed: {
    // Show/hide Sidebar and Header depending on the route name
    showMenu() {
      // Return false for login route to hide Sidebar and Header
      return !["Login", "ForgotPasswordInput", "ForgotPasswordForm"].includes(
        this.$route.name
      );
    },
    contentPanelStyle() {
      return {
        width: this.showMenu
          ? this.sidebarCollapsed
            ? "calc(100vw - 75px)"
            : "calc(100vw - 280px)"
          : "100%",
      };
    },
  },
  methods: {
    // Toggle the sidebar's collapsed state
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
  },
  created() {
    /**
     * Authentication check
     * Redirects user to Login page if not authenticated
     */
    this.showMenu && general.GetMe();
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  height: 100vh;
}

.content-panel {
  transition: width 0.3s ease-out;
  flex-grow: 1;
}

/* When the sidebar is visible, the content panel adjusts */
.content-panel.with-sidebar {
  width: calc(100vw - 280px); /* Adjust width when sidebar is present */
}

/* When there is no sidebar, the content panel takes full width */
.content-panel.no-sidebar {
  width: 100%;
}
</style>
